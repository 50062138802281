export enum StatPeriodGroup {
  DAYS  = 'DAYS',
  WEEKS  = 'WEEKS',
  MONTHS  = 'MONTHS',
  YEARS  = 'YEARS',
  NONE = 'NONE',
}

export enum StatPeriodMetrics {
  ReplyCount = 'ReplyCount',
  MessageCount = 'MessageCount',
  TriggerCall = 'TriggerCall',
  ReputationTake = 'ReputationTake',
  ReputationAdd = 'ReputationAdd',
  ApTake = 'ApTake',
  ApAdd = 'ApAdd',
  XpTake = 'XpTake',
  XpAdd = 'XpAdd',
  OtherCommand = 'OtherCommand',
  PunishCommandTake = 'PunishCommandTake',
  PunishCommandAdd = 'PunishCommandAdd',
  WarnCommandTake = 'WarnCommandTake',
  WarnCommandAdd = 'WarnCommandAdd',
}
