import { PeriodMetricsFields } from "@/includes/types/UsersStatByPeriod/types";

export default function getMetricColor(metric: keyof PeriodMetricsFields):string {
  if (metric === 'reply_count') {
    return 'rgba(var(--a-primary), 1)'
  } else if (metric === 'message_count') {
    return 'rgba(var(--a-warning), 1)'
  } else if (metric === 'trigger_call') {
    return 'rgba(var(--a-dark), 1)'
  } else if (metric === 'reputation_take') {
    return '#546D64'
  } else if (metric === 'reputation_add') {
    return '#4FB286'
  } else if (metric === 'ap_take') {
    return '#5a189a'
  } else if (metric === 'ap_add') {
    return '#9d4edd'
  } else if (metric === 'xp_take') {
    return '#804c36'
  } else if (metric === 'xp_add') {
    return '#e2b58b'
  } else if (metric === 'other_command') {
    return '#EECF6D'
  } else if (metric === 'punish_command_take') {
    return '#a4161a'
  } else if (metric === 'punish_command_add') {
    return '#e56b6f'
  } else if (metric === 'warn_command_take') {
    return '#fb6f92'
  } else if (metric === 'warn_command_add') {
    return '#ffb3c6'
  } else {
    return '#004E89'
  }
}
