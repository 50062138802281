var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-stat-by-period"},[(_vm.getTagsByFieldKey('statistic_users_analysis'))?_c('not-available-options-overlay',{attrs:{"tags":_vm.getTagsByFieldKey('statistic_users_analysis')}}):_c('div',{staticClass:"flex flex-col xl:flex-row lg:justify-between gap-2"},[_c('select-input',{staticClass:"w-full users-stat-by-period__metrics",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'selectedMetrics',
          'options': _vm.metricOptions,
          'multiple': true,
          'validation': 'required'
        }
      }},on:{"input":_vm.requestStat}}),_c('div',{staticClass:"w-full"},[_c('set-period',{on:{"searchPeriod":_vm.requestStat},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('tags-select',{staticClass:"flex justify-end flex-wrap mt-2",attrs:{"title":_vm.$t('period_groups_title'),"options":_vm.periodGroupOptions,"current-tag":_vm.group},on:{"onTagSelect":_vm.handleChange}})],1)],1),(_vm.selectedMetrics.length > 0 && _vm.loaded)?[(_vm.showGlobal)?_c('user-stat-global-card',{key:("totals_" + _vm.updateChartKey),staticClass:"mt-5",attrs:{"metrics":_vm.selectedMetricsInSnakeCase,"group":_vm.group,"period":_vm.period}}):_vm._e(),_c('tags-select',{staticClass:"flex justify-end flex-wrap mt-2",attrs:{"title":_vm.$t('period_category_title'),"options":_vm.categoryOptions,"current-tag":_vm.category},on:{"onTagSelect":_vm.handleCategoryChange}}),_c('user-stat-users-data',{key:("users_" + _vm.updateChartKey),staticClass:"mt-2",attrs:{"metric-columns":_vm.selectedMetricsInSnakeCase,"group":_vm.group,"category":_vm.category,"period":_vm.period}})]:_c('empty-data',{staticClass:"mt-5",attrs:{"description":_vm.$t('empty_metrics_notification')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }