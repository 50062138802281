import moment from "moment";

export default function convertLabels(labels:Array<string>):Array<string> {
  return labels.map((l: string, i: number, array:Array<string>) => {
    const start = moment(l).format('YYYY-MM-DD')
    const end = array[i + 1] ? moment(array[i + 1]).format('YYYY-MM-DD') : null

    let res = start

    if (end) {
      res = `${ start } - ${ end }`
    }

    return res
  })
}
