import {
  PeriodMetricsFields,
  PeriodMetricsTotalAlias,
  PeriodMetricsMaxAlias
} from "@/includes/types/UsersStatByPeriod/types";

export function mapToPeriodMetricsTotalAlias(key: keyof PeriodMetricsFields): PeriodMetricsTotalAlias {
  return `total_${ key }`
}

export function mapToPeriodMetricsMaxAlias(key: keyof PeriodMetricsFields): PeriodMetricsMaxAlias {
  return `max_${ key }`
}
