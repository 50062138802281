










































import getMetricColor from '@/components/UsersStatByPeriod/logic/getMetricColor'
import {
  PeriodMetricsFields,
  PeriodMetricsTotalAlias,
  TotalFields,
  UsersStatPeriod
} from '@/includes/types/UsersStatByPeriod/types'
import { StatPeriodGroup } from '@/includes/types/UsersStatByPeriod/Enums'
import { GlobalMetricRecord } from '@/components/UsersStatByPeriod/types'
import convertLabels from '@/components/UsersStatByPeriod/logic/convertLabels'
import { errorNotification } from '@/includes/NotificationService'
import { dateLabels, totalFields } from './UsersAnalyseService'

import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    VueApexCharts
  }
})
export default class UserStatGlobalCard extends Vue {
  @Prop({ type: Array }) metrics!: Array<keyof PeriodMetricsFields>

  @Prop({ type: String }) group!: StatPeriodGroup

  @Prop() period!: { from: string, to: string }

  updateChartKey = 0

  globalMetrics: Array<GlobalMetricRecord> | null = null

  cardsLoading = false

  getChartData(totals: UsersStatPeriod['total'], metric: keyof PeriodMetricsFields): ApexChartSeries {
    return [ {
      name: this.$t(`series_name_${ metric }`).toString(),
      data: totals.map(t => t[metric] ?? 0)
    } ]
  }

  setData(rootStat: TotalFields): Promise<Array<GlobalMetricRecord>> {
    return new Promise(resolve => {

      const stat = this.metrics
        .map(metric => {
          const totalMetric: PeriodMetricsTotalAlias = `total_${ metric }`

          return {
            name: metric,
            total: rootStat[totalMetric] ?? '-',
            series: this.group === StatPeriodGroup.NONE
              ? null
              : this.getChartData(rootStat.total, metric)
          }
        })

      resolve(stat)
    })
  }

  columnOptions(metric: keyof PeriodMetricsFields): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: '100',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ getMetricColor(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '100%'
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(dateLabels()),
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => val.toFixed(0).toString(),
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  mounted(): void {
    this.cardsLoading = true

    totalFields(this.metrics)
      .then(fields => {
        this.setData(fields)
          .then((data) => {
            this.globalMetrics = data
            this.updateChartKey += 1
          })
          .catch(errorNotification)
          .finally(() => {
            this.cardsLoading = false
          })

      })
      .catch(errorNotification)
  }
}
