
















import { SelectOption } from 'piramis-base-components/src/logic/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class TagsSelect extends Vue {
  @Prop() title!: string

  @Prop() options!: Array<SelectOption> | Array<SelectOptionData>

  @Prop() currentTag!: any

  @Emit('onTagSelect')
  onTagSelect(value: SelectOption['value'] | SelectOptionData['value']) {
    return value
  }

}
