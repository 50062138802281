



















import UsersStatByPeriod from '@/components/UsersStatByPeriod/UsersStatByPeriod.vue'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    UsersStatByPeriod,
    PageTitle,
    MobileLandscapeTriggerLayout
  }
})
export default class StatisticsUsersAnalyze extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {
  globalCardVisible = true

  key = 0

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.key += 1
  }

  mounted():void {
    this.globalCardVisible = !this.$store.state.dashboardExtendedUsers.length

    this.$baseTemplate.saveButton.hide()
  }

  destroyed():void {
    this.$baseTemplate.saveButton.show()

    this.$store.commit('clear_users')
  }
}
