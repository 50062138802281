import { StatPeriodGroup, StatPeriodMetrics } from "./Enums";

export type PeriodMetricsFields = Partial<{
  reply_count: number
  message_count: number
  trigger_call: number
  reputation_take: number
  reputation_add: number
  ap_take: number
  ap_add: number
  xp_take: number
  xp_add: number
  other_command: number
  punish_command_take: number
  punish_command_add: number
  warn_command_take: number
  warn_command_add: number
}>

export type PeriodMetricsTotalAlias = `total_${ keyof PeriodMetricsFields }`
export type PeriodMetricsTotalFields = Partial<{
  [key in PeriodMetricsTotalAlias]: number
}>

export type PeriodMetricsMaxAlias = `max_${ keyof PeriodMetricsFields }`
export type PeriodMetricsMaxFields = Partial<{
  [key in PeriodMetricsMaxAlias]: number
}>
// leave enter active
export enum UsersStatCategory {
  Enter = "enter",
  Leave = "leave",
  // Active = "active",
}

export type UsersStatRequestBody = {
  chat_id: number,
  group: StatPeriodGroup,
  from:string,
  to:string,
  metrics: Array<StatPeriodMetrics>,
  category?: UsersStatCategory
}

export type UsersStatPeriodTotalRecord = {
  date:string
} & PeriodMetricsFields

export type UsersStatPeriodUserRecord = {
  data: Array<UsersStatPeriodTotalRecord>
  total: PeriodMetricsFields | null
  user_id: number
  avatar: string
  login: string | null
  name: string
  language_code: 'ru' | 'en' | undefined
  enter?: string
  leave?: string
}

export type UsersStatPeriod = {
  total: Array<UsersStatPeriodTotalRecord>
  users: Array<UsersStatPeriodUserRecord>
} & PeriodMetricsMaxFields & PeriodMetricsTotalFields

export type TotalFields = Pick<UsersStatPeriod, "total"> & PeriodMetricsTotalFields
