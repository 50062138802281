import { render, staticRenderFns } from "./TagsSelect.vue?vue&type=template&id=556ca489&scoped=true&"
import script from "./TagsSelect.vue?vue&type=script&lang=ts&"
export * from "./TagsSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556ca489",
  null
  
)

export default component.exports