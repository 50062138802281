import {
  PeriodMetricsFields,
  PeriodMetricsMaxAlias,
  PeriodMetricsTotalAlias, PeriodMetricsTotalFields, TotalFields,
  UsersStatPeriod, UsersStatPeriodUserRecord, UsersStatRequestBody
} from "@/includes/types/UsersStatByPeriod/types";
import {
  mapToPeriodMetricsMaxAlias,
  mapToPeriodMetricsTotalAlias
} from "@/components/UsersStatByPeriod/logic/mapToPeriodMetricsTotalAlias";
import { errorNotification } from "@/includes/NotificationService";
import checkWebWorkersSupport from "@/includes/helpers/checkWebWorkersSupport";
import trimAndLowCaseFormat from "@/components/UsersStatByPeriod/logic/trimAndLowCaseFormat";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import axios, { AxiosRequestConfig } from "axios";
import SWorker from 'simple-web-worker'
import { cloneDeep } from "lodash";

export let usersStatByPeriod: UsersStatPeriod | null = null
export let usersStatByPeriodBackup: UsersStatPeriod | null = null
const isWorkerSupports = checkWebWorkersSupport()

export function requestData(body: UsersStatRequestBody): Promise<void> {
  const options: AxiosRequestConfig = {
    withCredentials: true,
  }

  if (isWorkerSupports) {
    options.transformResponse = (res) => res
  }

  return new Promise((resolve) => {
    axios.post(`${ PostApi.getApiUrl() }tg/getusersstatbyperiod`, { ...body }, { ...options })
        .then(res => {
          if (isWorkerSupports) {

            SWorker.run((json: string) => JSON.parse(json), [ res.data ])
                .then((parsedStatisticsJson: UsersStatPeriod) => {

                  usersStatByPeriod = parsedStatisticsJson;
                  usersStatByPeriodBackup = cloneDeep(parsedStatisticsJson);

                  resolve()
                })

          } else {

            usersStatByPeriod = res.data;
            usersStatByPeriodBackup = cloneDeep(res.data);

          }
        })
        .catch(errorNotification)
  })
}

export function totalFields(metrics: Array<keyof PeriodMetricsFields>) {
  return new Promise<TotalFields>((resolve, reject) => {

    if (usersStatByPeriod) {

      const { users, ...neededFields } = usersStatByPeriod
      const fieldKeys: Array<PeriodMetricsTotalAlias> = metrics.map(mapToPeriodMetricsTotalAlias)

      if (isWorkerSupports) {
        SWorker.run((fields: Array<PeriodMetricsTotalAlias>, model: Omit<UsersStatPeriod, 'users'>) => {
          return fields.reduce((obj: PeriodMetricsTotalFields, key) => {
            obj[key] = model[key] ?? 0

            return obj
          }, {})

        }, [ fieldKeys, neededFields ])
            .then((res: PeriodMetricsTotalFields) => {
              resolve({
                ...res,
                total: neededFields.total
              })
            })

      } else {

        const metrics = fieldKeys.reduce((obj: PeriodMetricsTotalFields, key) => {
          obj[key] = neededFields[key] ?? 0

          return obj
        }, {});

        resolve({
          ...metrics,
          total: neededFields.total
        })
      }

    } else {
      reject()
    }
  })
}

export function maxFields(metrics: Array<keyof PeriodMetricsFields>) {
  const { users, ...neededFields } = usersStatByPeriod!
  const fieldKeys: Array<PeriodMetricsMaxAlias> = [ ...metrics.map(mapToPeriodMetricsMaxAlias) ]

  return fieldKeys.reduce((obj: Partial<Record<PeriodMetricsMaxAlias, number>>, key) => {
    obj[key] = neededFields[key] ?? 0

    return obj
  }, {})
}

export function sortListByMetric(list: UsersStatPeriod, metric: keyof PeriodMetricsFields = "message_count", order: "descend" | "ascend" = "descend") {
  const makeSort = (a: UsersStatPeriodUserRecord, b: UsersStatPeriodUserRecord) => (a.total === null ? 0 : a.total[metric] ?? 0) - (b.total === null ? 0 : b?.total[metric] ?? 0)

  if (order === "descend") {
    return list.users.sort((a, b) => makeSort(b, a))
  } else {
    return list.users.sort((a, b) => makeSort(a, b))
  }
}

export function dateLabels(): Array<string> {
  if (usersStatByPeriod) {
    return usersStatByPeriod.total.map(record => record.date)
  }

  return []
}

export function dataLength() {
  if (usersStatByPeriod) {
    return usersStatByPeriod.users.length
  }

  return 0
}

export function setUsersToDefault() {
  usersStatByPeriod!.users = cloneDeep(usersStatByPeriodBackup!.users)
}

export function filterByNameOrLogin(names: Array<string>, filterCondition: "show" | "hide") {
  if (filterCondition === 'show') {
    usersStatByPeriod!.users = usersStatByPeriodBackup!.users.filter(value => userNamesFilter(value, names))
  }
  if (filterCondition === 'hide') {
    usersStatByPeriod!.users = usersStatByPeriodBackup!.users.filter(value => !userNamesFilter(value, names))
  }
}

function userNamesFilter(value: UsersStatPeriodUserRecord, usersToCheck: Array<string> | string): boolean {
  return (!!value.name && usersToCheck.includes(trimAndLowCaseFormat(value.name))) ||
      (!!value.login &&
          (
              usersToCheck.includes(trimAndLowCaseFormat(value.login)) ||
              usersToCheck.includes(trimAndLowCaseFormat(`@${ value.login }`))
          )
      )
}

export function filterUsersByIds(ids: Array<string>): Promise<void> {
  return new Promise(resolve => {
    if (isWorkerSupports) {
      SWorker.run((users: Array<UsersStatPeriodUserRecord>, ids: Array<string>) => {

        return users.filter(u => ids.indexOf(u.user_id.toString()) !== -1)

      }, [ usersStatByPeriod!.users, ids ])
          .then((res: Array<UsersStatPeriodUserRecord>) => {
            usersStatByPeriod!.users = res
            resolve()
          })
    } else {
      usersStatByPeriod!.users = usersStatByPeriod!.users.filter(u => ids.indexOf(u.user_id.toString()) !== -1)
      resolve()
    }
  })
}
