

































































































import {
  PeriodMetricsFields,
  PeriodMetricsMaxFields,
  UsersStatCategory,
  UsersStatPeriod,
  UsersStatPeriodTotalRecord,
  UsersStatPeriodUserRecord
} from '@/includes/types/UsersStatByPeriod/types'
import {
  UserStatDataRecord,
} from '@/components/UsersStatByPeriod/types'
import { StatPeriodGroup } from '@/includes/types/UsersStatByPeriod/Enums'
import getMetricColor from '@/components/UsersStatByPeriod/logic/getMetricColor'
import TableSearch from '@/components/UsersStatByPeriod/TableSearch.vue'
import trimAndLowCaseFormat from '@/components/UsersStatByPeriod/logic/trimAndLowCaseFormat'
import convertLabels from '@/components/UsersStatByPeriod/logic/convertLabels'
import {
  dateLabels,
  maxFields,
  sortListByMetric,
  usersStatByPeriod,
  usersStatByPeriodBackup,
  dataLength,
  filterUsersByIds,
  setUsersToDefault, filterByNameOrLogin
} from '@/components/UsersStatByPeriod/UsersAnalyseService'
import TelegramProfileButton from "@/components/TelegramProfileButton.vue";

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import { ApexChartLocales, ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { maxBy } from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import SWorker from 'simple-web-worker'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

type ChartInfo = {
  options: ApexOptions,
  series: ApexChartSeries
}

type UserChartInfo = Partial<{
  [key in keyof PeriodMetricsFields]: ChartInfo
}>

@Component({
  components: {
    TelegramProfileButton,
    TableSearch,
    UserMainInfo,
    VueApexCharts,
    TableExportButtons
  },
  data() {
    return {
      StatPeriodGroup,
      dataLength
    }
  }
})
export default class UserStatUsersData extends Vue {
  @Prop({ type: Array }) metricColumns!: Array<keyof PeriodMetricsFields>

  @Prop({ type: String }) group!: StatPeriodGroup

  @Prop({ type: String }) category!: UsersStatCategory | null

  @Prop() period!: { from: string, to: string }

  tableExportData() {
    return this.userMetricsData(
      usersStatByPeriod!.users,
      this.getNewMaxValues(usersStatByPeriod!.users)
    )
      .then(res => {
        return res.reduce((acc: Array<Record<string, number | string>>, record) => {
          acc.push({
            [this.$t('stat_users_data_table_col_user').toString()]: `${ record.name } ${ record.login ? `(${ atSignedLogin(record.login) })` : '' }`,
            ...this.metricColumns.reduce((metricFields: Record<string, number | string>, metric) => {
              metricFields[this.$t(metric).toString()] = record?.total?.[metric] ?? '-'

              return metricFields
            }, {})
          })
          return acc
        }, [  ])
      })
  }

  sortedInfo = {
    order: 'descend',
    columnKey: this.metricColumns[0],
  }

  updateTableKey = 0

  tableData: Array<UserStatDataRecord> = []

  tableLoading = false

  dataLoaded = false

  updateTableDataKey = 0

  get extendedUsersLength() {
    return this.$store.state.dashboardExtendedUsers.length
  }

  get columns() {
    return [
      {
        title: this.$t('stat_users_data_table_col_user'),
        key: 'user-info',
        width: 270,
        scopedSlots: { customRender: 'user-info' },
      },
      ...this.metricColumns.map(metric => ({
        title: this.$t(metric),
        key: metric,
        width: 250,
        scopedSlots: { customRender: metric },
        defaultSortOrder: 'descend',
        sorter: (a: UserStatDataRecord, b: UserStatDataRecord) => (a.total === null ? 0 : a.total[metric] ?? 0) - (b.total === null ? 0 : b?.total[metric] ?? 0)
      }))
    ]
  }

  get chartWidth() {
    if (this.$screen.xl) {
      return '300px'
    }

    return '250px'
  }

  loadInitList():Promise<Array<string>> {
    return new Promise((resolve) => {
      if (this.$store.state.dashboardExtendedUsers.length) {

        SWorker.run((users: Array<UsersStatPeriodUserRecord>, filterList: Array<string>) => {
          return users
            .filter(u => filterList.indexOf(u.user_id.toString()) !== -1)
            .map(u => u.login || u.name)

        }, [ usersStatByPeriodBackup!.users, this.$store.state.dashboardExtendedUsers ])
          .then((res: Array<string>) => {
            resolve(res)
          })
      } else {
        resolve([])
      }
    })
  }

  //https://1x.antdv.com/components/table/#API api here
  handleChange(pagination: any, _: any, sorter: any) {
    const { columnKey, order } = sorter
    const pageSlice = pagination.current * pagination.pageSize

    if (columnKey !== this.sortedInfo.columnKey || (order && order !== this.sortedInfo.order)) {
      sortListByMetric(usersStatByPeriod!, columnKey, order)
      sortListByMetric(usersStatByPeriodBackup!, columnKey, order)

      this.sortedInfo.order = order
      this.sortedInfo.columnKey = columnKey
    }

    this.userMetricsData(
      this.frontendPagination(pageSlice - pagination.pageSize, pageSlice, usersStatByPeriod!.users),
      this.getNewMaxValues(usersStatByPeriod!.users)
    )
      .then(res => {
        this.tableData = res

        this.updateTableDataKey += 1
      })
  }

  userSearchQueryFilter(value: UsersStatPeriodUserRecord, searchString: string): boolean {
    const query = trimAndLowCaseFormat(searchString)

    return (!!value.name && trimAndLowCaseFormat(value.name).includes(query)) ||
      (!!value.login &&
        (
          trimAndLowCaseFormat(value.login).includes(query) ||
          trimAndLowCaseFormat(atSignedLogin(value.login)).includes(query)
        )
      )
  }

  findMaxMetricValue(array: Array<UsersStatPeriodTotalRecord>, metric: keyof PeriodMetricsFields): number {
    const maxValue = maxBy(array, (v) => v[metric])

    if (maxValue) {
      return maxValue[metric] ?? 0
    }

    return 0
  }

  getNewMaxValues(users: Array<UsersStatPeriodUserRecord>): PeriodMetricsMaxFields {
    const flatMax = users.map(u => u.data).flat()

    return this.metricColumns.reduce((resObj: PeriodMetricsMaxFields, metric) => {
      this.$set(resObj, `max_${ metric }`, this.findMaxMetricValue(flatMax, metric))

      return resObj
    }, {})
  }

  handleHideUsers(names: Array<string>) {
    filterByNameOrLogin(names, "hide")

    this.tableLoading = true

    setTimeout(() => {
      this.userMetricsData(this.frontendPagination(0, 10, usersStatByPeriod!.users), this.getNewMaxValues(usersStatByPeriod!.users))
        .then(res => {
          this.tableData = res
          this.tableLoading = false
        })
    }, 0)

  }

  handleShowUsers(names: Array<string>) {
    filterByNameOrLogin(names, "show")

    this.tableLoading = true

    setTimeout(() => {
      this.userMetricsData(this.frontendPagination(0, 10, usersStatByPeriod!.users), this.getNewMaxValues(usersStatByPeriod!.users))
        .then(res => {
          this.tableData = res
          this.tableLoading = false
        })
    }, 0)
  }

  generateColumnOptions(metric: keyof PeriodMetricsFields, maxValue: number): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        redrawOnParentResize: false
      },
      colors: [ getMetricColor(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(dateLabels()),
      },
      yaxis: {
        min: 0,
        max: maxValue,
        show: false
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number): string => val.toFixed(0).toString(),
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  generateChartData(userData: UsersStatPeriodUserRecord['data'], metric: keyof PeriodMetricsFields, newMaxFields?: PeriodMetricsMaxFields) {
    const labels = dateLabels()
    const defaultMaxFields = maxFields(this.metricColumns)[`max_${ metric }`] ?? 0

    const setSeriesData = (userData: UsersStatPeriodUserRecord['data'], metric: keyof PeriodMetricsFields): Array<number> => {
      const array = Array(labels.length).fill(0)

      userData.forEach(record => {
        let dateIndex = labels.indexOf(record.date)

        if (dateIndex !== -1) {
          array[dateIndex] = record[metric] ?? 0
        }
      })

      return array
    }

    return {
      options: this.generateColumnOptions(metric, newMaxFields ? newMaxFields[`max_${ metric }`] ?? 0 : defaultMaxFields),
      series: [ {
        name: metric,
        data: setSeriesData(userData, metric)
      } ]
    }
  }

  generateChartInfo(userData: UsersStatPeriodUserRecord['data'], newMaxFields?: PeriodMetricsMaxFields): UserChartInfo {
    return this.metricColumns.reduce((obj: UserChartInfo, metric) => {
      obj[metric] = this.generateChartData(userData, metric, newMaxFields)

      return obj
    }, {})
  }

  baseCategorySeries(userData: UsersStatPeriodUserRecord): Array<{ x: string, y: Array<number>, fillColor?: string }> {
    return [
      {
        x: 'activity',
        y: [
          new Date(moment(userData.enter).format('YYYY-MM-DD')).getTime(),
          new Date(moment(userData.leave).format('YYYY-MM-DD')).getTime(),
        ],
        fillColor: this.category === UsersStatCategory.Enter ? 'rgba(var(--a-success), 1)' : 'rgba(var(--a-danger), 1)'
      }
    ]
  }

  enterCategorySeries(userData: UsersStatPeriodUserRecord): Array<{ x: string, y: Array<number>, fillColor?: string }> {
    if (moment(moment(userData.leave)).isBefore(this.period.from)) {
      return [
        {
          x: 'activity',
          y: [
            new Date(moment(userData.enter).format('YYYY-MM-DD')).getTime(),
            new Date(moment(this.period.to).format('YYYY-MM-DD')).getTime(),
          ],
          fillColor: 'rgba(var(--a-success), 1)',
        }
      ]
    }

    if (moment(userData.leave).isBefore(moment(userData.enter))) {

      return [
        {
          x: 'activity',
          y: [
            new Date(moment(this.period.from).format('YYYY-MM-DD')).getTime(),
            new Date(moment(userData.leave).format('YYYY-MM-DD')).getTime(),
          ],
          fillColor: 'rgba(var(--a-success), 1)',
        },
        {
          x: 'activity',
          y: [
            new Date(moment(userData.enter).format('YYYY-MM-DD')).getTime(),
            new Date(moment(this.period.to).format('YYYY-MM-DD')).getTime(),
          ],
          fillColor: 'rgba(var(--a-success), 1)'
        }
      ]
    }

    return this.baseCategorySeries(userData)
  }

  leaveCategorySeries(userData: UsersStatPeriodUserRecord): Array<{ x: string, y: Array<number>, fillColor?: string }> {
    if (moment(userData.enter).isBefore(this.period.from)) {
      return [
        {
          x: 'activity',
          y: [
            new Date(moment(this.period.from).format('YYYY-MM-DD')).getTime(),
            new Date(moment(userData.leave).format('YYYY-MM-DD')).getTime(),
          ],
          fillColor: 'rgba(var(--a-danger), 1)'
        }
      ]
    }

    return this.baseCategorySeries(userData)
  }

  prepareUserActivityChart(userData: UsersStatPeriodUserRecord): any {
    const dates = []
    const from = moment(this.period.from)
    const to = moment(this.period.to)

    while (!from.isAfter(to)) {
      dates.push(new Date(moment(from).format('YYYY-MM-DD')).getTime())
      from.add(1, 'day')
    }

    const series = [
      {
        data: [
          {
            x: '',
            y: [
              new Date(moment(this.period.from).format('YYYY-MM-DD')).getTime(),
              new Date(moment(this.period.from).format('YYYY-MM-DD')).getTime()
            ]
          },
          {
            x: '',
            y: [
              new Date(moment(this.period.to).format('YYYY-MM-DD')).getTime(),
              new Date(moment(this.period.to).format('YYYY-MM-DD')).getTime(),
            ],
            fillColor: '',
          },
          ...this.category === UsersStatCategory.Enter ? this.enterCategorySeries(userData) : [],
          ...this.category === UsersStatCategory.Leave ? this.leaveCategorySeries(userData) : []
        ]
      }
    ]

    const options: ApexOptions = {
      chart: {
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales,
        zoom: {
          enabled: false
        },
        type: 'rangeBar',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        offsetY: -5
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: -10,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        axisTicks: {
          show: false,
        },
        show: false
      }
    }

    return {
      userChart: {
        options,
        series
      }
    }
  }

  userMetricsData(users: UsersStatPeriod['users'], newMaxFields?: PeriodMetricsMaxFields): Promise<Array<UserStatDataRecord>> {
    return new Promise<Array<UserStatDataRecord>>((resolve) => {
      const data = users.map(user => {
        return {
          ...user,
          ...this.category && [ UsersStatCategory.Enter, UsersStatCategory.Leave ].includes(this.category) ? this.prepareUserActivityChart(user) : undefined,
          ...this.generateChartInfo(user.data, newMaxFields)
        }
      })
      resolve(data)
    })
  }

  setDefaultTableData(): void {
    if (this.$store.state.dashboardExtendedUsers.length) {
      this.$store.commit('clear_users')
    }

    sortListByMetric(usersStatByPeriodBackup!, this.metricColumns[0])
    setUsersToDefault()

    this.userMetricsData(this.frontendPagination(0, 10, usersStatByPeriodBackup!.users))
      .then(res => {
        this.tableData = res
        this.updateKey()
      })
  }

  handleSearchQuery(search: string): void {
    this.userMetricsData(usersStatByPeriod!.users.filter(value => this.userSearchQueryFilter(value, search)))
      .then(res => {
        this.tableData = res
        this.updateKey()
      })
  }

  updateKey(): void {
    this.updateTableKey += 1
  }

  frontendPagination(from: number, to: number, list: Array<UsersStatPeriodUserRecord>): Array<UsersStatPeriodUserRecord> {
    return list.slice(from, to)
  }

  gotoUserProfile(userId:number) {
    this.$router.push({ name: 'user_info', params: { userId: userId.toString() } })
  }

  initData() {
    let newMaxFieldsArg: undefined | PeriodMetricsMaxFields = undefined

    if (this.extendedUsersLength) {
      newMaxFieldsArg = this.getNewMaxValues(usersStatByPeriod!.users)
    }

    sortListByMetric(usersStatByPeriod!, this.metricColumns[0])

    return this.userMetricsData(this.frontendPagination(0, 10, usersStatByPeriod!.users), newMaxFieldsArg)
      .then(res => {
        this.tableData = res
        this.updateKey()
      })
      .then(() => {
        sortListByMetric(usersStatByPeriodBackup!, this.metricColumns[0])
      })
      .finally(() => {
        this.dataLoaded = true
      })
  }

  mounted(): void {
    if (this.extendedUsersLength) {
      filterUsersByIds(this.$store.state.dashboardExtendedUsers)
        .then(this.initData)
        .finally(() => {
          this.dataLoaded = true
        })
    } else {
      this.initData()

    }
  }
}
